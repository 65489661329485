import * as moment from 'moment';
import * as _ from 'lodash';
import * as JsUtils from './jsUtils';
import { valueFilter } from "../pages/propertylist/propertyGrid/columnDefinition";
import { ClassificationCategoryTypeFromStatus, ClassificationToolTipSetup } from "../services/data/classification";
import { ListingProperty } from "../services/data/listingPropety";
import { PropertyStatusCategoryTypeFromStatus, PropertyStatusTypeText } from "../services/data/propertyStatusType";
import { ListingHistoryMap } from '../services/data/listingHistory';
import { ListingPreferences, UserFilter } from '../models/preferences';

export function initializeProperty(p: ListingProperty, histories?: ListingHistoryMap) {
  if (p) {
    p.StatusUpdateDateMoment = moment(p.StatusUpdateDate);
    p.StatusUpdateDateHuman = JsUtils.getDateCalendarString(p.StatusUpdateDateMoment);

    isDataMissingOrExceptions(p);

    if (p.Tags && typeof p.Tags === 'string') {
      p.Tags = p.Tags.split(',');
    }

    p.underwritingStatusText = PropertyStatusTypeText(p.underwritingStatus);
    p.underwritingStatusClass = 'a1-status-' + PropertyStatusCategoryTypeFromStatus(p.underwritingStatus);
    p.underwritingClassificationClass = ClassificationCategoryTypeFromStatus(p);
    ClassificationToolTipSetup(p, p.buyBoxClassification || p.interimBuyBoxClassification);
  }
}

export function isDataMissingOrExceptions(p: ListingProperty) {
  p.missingDataTooltip = ``;
  if (p.Exception) {
    p.missingDataTooltip = '<div class="a1-mask-icon alert-exception" data-qa="listingView-lbl-exceptionListing"></div>';
  } else if (!p.Beds || !p.BathsCount || !p.LivingAreaSqFt || !p.YearBuilt) {
    p.missingDataTooltip = '<div class="a1-mask-icon alert-circle"></div>';
    p.missingData = true;
  }
}

export function buildChannelValueFilter(props: ListingProperty[]) {
  (props || []).forEach((p) => {
    const mappedValue = p.Channel ? p.Channel : '-';
    if (!valueFilter.Channel.includes(mappedValue)) {
      valueFilter.Channel.push(mappedValue);
    }
  });
}

export function calculateDaysOnMarket(property): number {
  const now = moment().startOf('day');

  if (property.ListingDate) {
    const listingDate = moment(property.ListingDate).startOf('day');

    return now.diff(listingDate, 'days');
  }

  const lastUpdate = moment(property.LastUpdate).startOf('day');

  return now.diff(lastUpdate) + property.DaysOnMarket;
}

export function buildUnderwriterPreferences(preferences) {
  if (!preferences.propertyListing) {
    preferences.propertyListing = new ListingPreferences();
  }

  if (!preferences.bulkPropertyListing) {
    preferences.bulkPropertyListing = new ListingPreferences();
  }

  if (!preferences.compsListing) {
    preferences.compsListing = new ListingPreferences();
  }

  if (!preferences.transactionListing) {
    preferences.transactionListing = new ListingPreferences();
  }

  if (!preferences.constructionListing) {
    preferences.constructionListing = new ListingPreferences();
  }

  if (!preferences.leasingRentReadyListing) {
    preferences.leasingRentReadyListing = new ListingPreferences();
  }

  if (!preferences.leasingLeasedListing) {
    preferences.leasingLeasedListing = new ListingPreferences();
  }

  if (!preferences.closingListing) {
    preferences.closingListing = new ListingPreferences();
  }

  // Initialize optional data
  _.each(preferences.propertyListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.bulkPropertyListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.compsListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.transactionListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.constructionListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.leasingRentReadyListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.leasingLeasedListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  _.each(preferences.closingListing.filters, (f: UserFilter) => {
    if (!f.sortModel) {
      f.sortModel = [];
    }
  });

  // miragtion stuff
  if (preferences['filters']) {
    preferences.propertyListing.filters = preferences['filters'];
  }

  if (preferences['columns']) {
    preferences.propertyListing.columns = preferences['columns'];
  }
  return preferences;
}
