import * as _ from 'lodash';

import { ColDef } from 'ag-grid-community';
import {
  InvestorApprovalListType, InvestorApprovalStatusTypeText,
  PropertyStatusTypeText,
  UnderwritingListPropertyStatusType
} from 'src/app/services/data/propertyStatusType';
import { GridColumnTypes } from 'src/app/ui/agGrid/gridColumnTypes';
import { TagsUtils } from 'src/app/services/tagUtils';
import { EntityUtils } from "../../../services/entityUtils";

export const valueFilter: any = {
  Channel: []
};

export abstract class PropertyListingColumnDefinition {
  private static filterParams = {
    buttons: ['reset', 'apply'],
    closeOnApply: true,
    suppressSorting: false,
  };

  static headerColumn: ColDef[] = [
    {
      headerName: "",
      field: "isHeaderRow",
      width: 18, minWidth: 18,
      cellClass: 'dataChevronIcon',
      filter: false,
      hide: false,
      pinned: true,
      sortable: false,
      resizable: false,
      suppressMovable: true,
      lockPosition: true,
      showRowGroup: true,
      cellRendererSelector: params => {
        if (params.data.isHeaderRow && params.data.groupCount > 1) {
          return {
            component: 'groupRenderer',
            params: {
              suppressDoubleClickExpand: true,
              suppressEnterExpand: true,
            },
          };
        }

        return { component: () => '' };
      },
      tooltipValueGetter: (params) => {
        return 'tooltipGroup';
      },
    },
  ];

  static pinnedColumns: ColDef[] = [
    {
      headerName: "",
      field: "id",
      width: 48,
      filter: false,
      hide: false,
      pinned: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      suppressMovable: true
    },

    {
      headerName: "",
      field: "missingDataTooltip",
      width: 18, minWidth: 18, filter: false, hide: false, pinned: true, sortable: false, resizable: false,
      tooltipField: 'missingDataTooltip',
      cellClass: 'dataToolTipIcon',
      suppressMovable: true,
      cellRendererSelector: params => {
        if (params.data.missingDataTooltip) {
          return {
            component: compParams => {
              return compParams.value ? compParams.value : '';
            },
            params: {value: params.data.missingDataTooltip}
          };
        }

        return { component: () => '' };
      }
    },

    {
      headerName: "Maestro Status", field: "underwritingStatus", width: 150,
      comparator: GridColumnTypes.underwriterStatusComparator,
      keyCreator: GridColumnTypes.underwriterStatusKeyCreator,
      filter: "agSetColumnFilter",
      filterParams: {
        values: function (params) {
          setTimeout(function () {
            const values = [];

            _.each(UnderwritingListPropertyStatusType, (t) => {
              values.push(PropertyStatusTypeText(t));
            });

            // supply values to the set filter
            params.success(values);
          }, 10);
        },
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressSorting: true,
      }, hide: false, pinned: true,
      cellRenderer: 'underwriterStatusRenderer',
      suppressMovable: true,
      sort: 'asc',
      initialSort: 'asc'
    },

    {
      headerName: "Address",
      field: "Address",
      width: 216,
      cellClass: "a1-address" ,
      filter: "agTextColumnFilter",
      filterParams: {debounceMs:2000 },
      hide: false,
      pinned: true,
      type: 'updatedColumn',
      cellRenderer: "clipboardCopyForListingRenderer",
      suppressMovable: true,
    },

    {
      headerName: "Tags",
      field: "Tags",
      width: 260,
      minWidth: 240,
      hide: false,
      pinned: true,
      cellEditor: 'SelectCellGridComponent',
      cellRenderer: 'SelectCellGridComponent',
      cellClass: 'a1-tags-cell',
      cellRendererParams: {
        readonly: true,
      },
      cellEditorParams: {
        readonly: false,
      },
      editable: true,
      singleClickEdit: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: function (params) {
          TagsUtils.getTagsList().subscribe({
            next: tagListValue => {
              if(TagsUtils.canLoadTags() && tagListValue && tagListValue.length) {
                params.success(tagListValue);
                TagsUtils.alreadyLoaded = true;
              }
            },
            error: (err) => console.log(err)
          });
        },
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressSorting: true,
      },
      suppressMovable: true
    },
  ];

  static defaultColumns: ColDef[] = [
    { headerName: "Buy Box V2", field: "BuyBoxV2", width: 200, hide: false,
    filter: "agSetColumnFilter",
    type: 'leftColumn',
    filterParams: {
      values: [
        '-',
        'Core',
        'Standard',
        'Premium',
      ],
      buttons: ['reset', 'apply'],
    },
    },
    { headerName: "Buy Box V2.1", field: "UQClassification", width: 200, hide: false,
    filter: "agSetColumnFilter",
    type: 'leftColumn',
    filterParams: {
      values: [
        '-',
        'Core',
        'Standard',
        'Premium',
        'no_zone',
        'Affordable'
      ],
      buttons: ['reset', 'apply'],
    },
    },
    {
      headerName: "Maestro Updated",
      field: "StatusUpdateDateHuman",
      width: 150,
      hide: false,
      type: "calendarDateColumn",
      cellRenderer: "attributeRenderer",
      cellRendererParams: {
        attributes: ['data-date']
      }
    },
    {
      headerName: "Unit", field: "Unit", width: 150, filter: "agTextColumnFilter", filterParams: { debounceMs: 2000 },
      hide: true, type: "centerColumn"
    },
    {
      headerName: "City", headerClass: "text-centered", field: "City", width: 200, filter: "agTextColumnFilter",
      filterParams: this.filterParams, hide: false, type: "centerColumn"
    },
    {
      headerName: "State", headerClass: "text-centered", field: "State", width: 95, filter: "agTextColumnFilter",
      filterParams: this.filterParams, hide: false, type: "centerColumn"
    },
    {
      headerName: "Zip Code", field: "ZipCode", width: 110, hide: false, type: 'zipColumn', filter: "agTextColumnFilter",
      filterParams: this.filterParams
    },
    {
      headerName: "Region", headerClass: "text-centered", field: "Region", width: 150, filter: "agTextColumnFilter",
      filterParams: this.filterParams, hide: false, type: "centerColumn"
    },
    {
      headerName: "FIPS", field: "FIPS", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true, type: "centerColumn"
    },
    {
      headerName: "County Name", field: "CountyName", width: 200, filter: "agTextColumnFilter",
      filterParams: this.filterParams, hide: true, type: "centerColumn"
    },
    {
      headerName: "Census Tract", field: "CensusTract", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true
    },
    {
      headerName: "Latitude", field: "Latitude", width: 150, filter: "number", hide: true
    },
    {
      headerName: "Longitude", field: "Longitude", width: 150, filter: "number", hide: true
    },
    {
      headerName: "Beds", field: "Beds", width: 95, hide: false, type: 'numberColumn'
    },
    {
      headerName: "Full Baths", field: "FullBaths", width: 95, hide: true, type: 'numberColumn'
    },
    {
      headerName: "Half Baths", field: "HalfBaths", width: 95, hide: true, type: 'numberColumn'
    },
    {
      headerName: "Baths", field: "BathsCount", width: 95, hide: false, type: 'numberColumn'
    },
    {
      headerName: "Year Built", field: "YearBuilt", width: 110, hide: false, type: 'numberColumn'
    },
    {
      headerName: "Sq Ft", field: "LivingAreaSqFt", width: 95, hide: false, type: 'numberColumn'
    },
    {
      headerName: "Purchasing Entity", field: "purchasingEntity", width: 150, hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: function (params) {
          EntityUtils.getEntities().subscribe({
            next: entitiesValue => {
              if(EntityUtils.canLoadEntities()) {
                params.success(entitiesValue.map(o => o.label));
                EntityUtils.alreadyLoaded = true;
              }
            },
            error: (err) => console.log(err)
          });
        }
        ,
        buttons: ['reset', 'apply'],
        closeOnApply: true,
      },
      type: 'leftColumn'
    },
    {
      headerName: "Listing Status Date", field: "StatusDate", width: 135, hide: true, type: 'UTCDateColumn'
    },
    {
      headerName: "Current Price", field: "CurrentPrice", width: 160, hide: false, type: 'currencyUpdatedColumn',
      cellRenderer: 'CurrentPriceRenderer'
    },
    {
      headerName: "Bid Price", field: "uwPurchasePrice", width: 120, hide: false, type: 'currencyColumn'
    },
    {
      headerName: "AVM Cap Rate", field: "avmCapRate", width: 150, hide: false, type: 'percentColumn'
    },
    {
      headerName: "Target Cap Rate", field: "avmTargetCapRate", width: 150, hide: false, type: 'percentColumn'
    },
    {
      headerName: "UW Cap Rate", field: "uwCapRate", width: 150, hide: false, type: 'percentColumn'
    },
    {
      headerName: "Days On Market", field: "RealDaysOnMarket", width: 125, hide: false, type: 'numberColumn'
    },
    {
      headerName: "Listing Date", field: "ListingDate", width: 135, hide: false, type: 'UTCDateColumn'
    },
    {
      headerName: "Listing Agent Name", field: "ListingAgentName", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true
    },
    {
      headerName: "Listing Broker Name", field: "ListingBrokerName", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true
    },
    {
      headerName: "Listing Agent Phone", field: "ListingAgentPhone", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true
    },
    {
      headerName: "Listing Agent Email", field: "ListingAgentEmail", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000 }, hide: true
    },
    {
      headerName: "Estimated Rent", field: "EstimatedRent", width: 130, hide: false, type: 'currencyColumn'
    },
    {
      headerName: "Estimated Rent Std Dev", field: "EstimatedRentStdDev", width: 150, hide: true, type: 'currencyColumn'
    },
    {
      headerName: "Estimated Rehab Cost", field: "avmRehabBudget", width: 142, hide: false, type: 'currencyColumn'
    },
    {
      headerName: "Rehab Budget", field: "uwRehabBudget", width: 130, hide: false, type: 'currencyColumn'
    },
    { headerName: "Sale AVM", field: "SaleAVM", width: 130, hide: false, type: 'currencyColumn' },
    { headerName: "Sale AVM Std Dev", field: "SaleAVMStdDev", width: 150, hide: true, type: 'currencyColumn' },
    {
      headerName: "Listing Status", field: "ListingStatus", width: 150, filter: "agTextColumnFilter",
      filterParams: this.filterParams, type: 'listingStatusUpdatedColumn', hide: false, cellRenderer: "ListingStatusRenderer"
    },
    { headerName: "Closing Cost", field: "ClosingCost", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Acquisition Cost", field: "AcquisitionCost", width: 150, hide: true, type: 'percentColumn' },

    { headerName: "OccupancyRate", field: "OccupancyRate", width: 150, hide: true, type: 'percentColumn' },
    { headerName: "Credit Loss", field: "CreditLoss", width: 150, hide: true, type: 'percentColumn' },

    { headerName: "Concessions", field: "Concessions", width: 150, hide: true, type: 'percentColumn' },
    { headerName: "Other Income", field: "OtherIncome", width: 150, hide: true, type: 'percentColumn' },

    { headerName: "Management Fee", field: "ManagementFee", width: 150, hide: true, type: 'percentColumn' },

    { headerName: "HOADues", field: "HOADues", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Leasing Cost", field: "LeasingCost", width: 150, hide: true, type: 'currencyColumn' },

    { headerName: "Property Type", field: "PropertyType", width: 150, hide: true, type: 'centerUpdatedColumn',
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          'MF',
          'SFA',
          'SFD',
        ]
      },
    },
    { headerName: "Property Subtype", field: "PropertySubtype", width: 150, hide: true, type: 'centerUpdatedColumn',
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          'Apartment',
          'Condo',
          'Manufactured',
          'Site Built',
          'Townhouse',
        ]
      },
    },
    { headerName: "Orig Price", field: "OrigPrice", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Public Remarks", field: "PublicRemarks", width: 150, filter: "agTextColumnFilter", filterParams: { debounceMs: 2000 }, hide: true },
    { headerName: "Private Remarks", field: "PrivateRemarks", width: 150, filter: "agTextColumnFilter", filterParams: { debounceMs: 2000 }, hide: true },
    { headerName: "MLSName", field: "MLSName", width: 150, filter: "agTextColumnFilter", filterParams: this.filterParams, hide: true },
    { headerName: "MLSListing ID", field: "MLSListingID", width: 150, filter: "agTextColumnFilter", hide: true },

    { headerName: "Property Tax Appraisal", field: "PropertyTaxAppraisal", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Current Property Tax", field: "CurrentPropertyTax", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Property Tax Year", field: "PropertyTaxYear", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "SFHA", field: "SFHA", width: 150, filter: "agTextColumnFilter",filterParams: {debounceMs:2000 },  hide: true },
    { headerName: "Property Tax Rate", field: "PropertyTaxRate", width: 150, hide: true, type: 'percentColumn' },
    { headerName: "Condition Score", field: "ConditionScore", width: 150, hide: true, type: 'numberColumn' },
    {
      headerName: "Occupant", field: "Occupant", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000, ...this.filterParams }, hide: true, type: 'centerColumn'
    },
    {
      headerName: "Owner Name", field: "OwnerName", width: 150, filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000, ...this.filterParams }, hide: true
    },
    { headerName: "Last Sale Date", field: "LastSaleDate", width: 150, filter: "agDateColumnFilter", hide: true, type: 'UTCDateColumn' },
    { headerName: "Last Sale Price", field: "LastSalePrice", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Last Rent Date", field: "LastRentDate", width: 150, filter: "agDateColumnFilter", hide: true, type: 'UTCDateColumn' },
    { headerName: "Last Rent", field: "LastRent", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Zestimate", field: "Zestimate", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Median Income", field: "MedianIncome", width: 150, hide: true, type: 'currencyColumn' },
    { headerName: "Inst. %", field: "PctInstitutionalOwnership", width: 150, hide: true, type: 'roundedPercentColumn' },
    { headerName: "Elementary School Score", field: "SchoolScoreElementary", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Middle School Score", field: "SchoolScoreMiddle", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "High School Score", field: "SchoolScoreHigh", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Crime Score", field: "CrimeScore", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "Maestro ID", field: "AOListingID", width: 150, hide: true, type: 'numberColumn' },
    { headerName: "AO Property ID", field: "AOPropertyID", width: 150, hide: true, type: 'numberColumn', rowGroup: true },
    { headerName: "AO Last Update", field: "LastUpdate", width: 150, filter: "agDateColumnFilter", hide: true, type: 'UTCDateColumn' },
    { headerName: "Group Count", field: "groupCount", width: 150, filter: "agNumberColumnFilter",hide: true, type: "numberColumn" },
    {
      headerName: "Investor Approval",
      field: "investorApproval",
      width: 150,
      filter: "agSetColumnFilter",
      filterParams: {
        values: function (params) {
          setTimeout(function () {
            const values = [];

            _.each(InvestorApprovalListType, (t) => {
              // @ts-ignore
              values.push(InvestorApprovalStatusTypeText(t));
            });

            params.success(values);
          }, 10);
        },
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressSorting: true,
      },
      hide: true,
      valueFormatter: function (params) {
        return InvestorApprovalStatusTypeText(params?.data.investorApproval);
      }
    },
    { headerName: "Channel",
      field: "Channel",
      width: 150,
      filter: 'agSetColumnFilter',
      hide: false,
      type: "leftColumnOther",
      filterParams: {
        values: ['Other', 'MLS', 'Offerpad', 'OffMarket', 'Bulk'],
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        suppressSorting: true
      }
    },
    { headerName: "Buy Box Classification", field: "buyBoxClassification", width: 150, hide: false,
      filter: "agSetColumnFilter",
      type: 'leftColumn',
      filterParams: {
        values: [
          '-',
          'Affordable',
          'Standard',
          'Premium',
          'None',
          'Error'
        ],
        buttons: ['reset', 'apply'],
      },
    },
    {
      headerName: "Status",
      field: "TMStatus",
      width: 200,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          '-',
          'New Transaction',
          'Offer Delivered',
          'Under Contract',
          'Buyer Clear to Close',
          'Closed & Funded',
          'Canceled',
          'LostDead',
        ],
        buttons: ['reset', 'apply']
      },
      hide: false,
      type: "leftColumn"
    },
    {
      headerName: "Substatus",
      field: "subStatus",
      width: 200,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          '-',
          'Counter Offer',
          'Buyer Docs Pending',
          'Buyer Signed',
          'Concession Requested',
          'Closing Docs Received',
          'Trailing Docs Pending',
          'Trailing Docs Received',
          'Pre-Inspection',
          'Terminating'
        ],
        buttons: ['reset', 'apply']
      },
      hide: false,
      type: "leftColumn"
    },
    {
      headerName: "Inspection Status",
      field: "inspectionStatus",
      width: 200,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          '-',
          'New',
          'Inspection Assigned',
          'Inspection Scheduled',
          'Underwriter Review Pending',
          'Inspection Completed',
          'Inspection Past Due',
          'Extension Required',
          'Unable to Inspect',
          'DD At Risk',
          'Do Not Buy'
        ],
        buttons: ['reset', 'apply']
      },
      hide: false,
      type: "leftColumn"
    },

    { headerName: "Interim Classification", field: "BuyBox", width: 200, hide: false,
      filter: "agSetColumnFilter",
      type: 'leftColumn',
      filterParams: {
        values: [
          '-',
          'Affordable',
          'Standard',
          'Premium',
        ],
        buttons: ['reset', 'apply'],
      },
    },
    {
      headerName: "Rejection Reasons",
      field: "rejectionReasons",
      width: 200,
      filter: "agTextColumnFilter",
      hide: true,
      type: "leftColumn",
      valueFormatter: function (params) {
        return params.value?.split(' \\ ').join(', ');
      },
      filterParams: {
        buttons: ['reset', 'apply'],
      }
    },
    { headerName: "AI-ALM", field: "AiAlm", width: 125, hide: true, type: 'numberColumn' },
    {
      headerName: "Lot Size Acres", field: "LotSizeAcres", width: 150, hide: false, type: "numberColumn", valueFormatter: function (params) {
        return params.value ? params.value.toFixed(2) : '-';
      }
    }
  ];

  static uwDefaultColumns: ColDef[] = [
    {
      headerName: "Bulk Portfolio Name",
      field: "AOPortfolioID",
      type: 'leftColumn',
      width: 200,
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000, ...this.filterParams },
      valueGetter: params => params.data?.AOPortfolioID?.replace(/_/gi, ' '),
    }
  ];

  static bulkDefaultColumns: ColDef[] = [
    {
      headerName: "Bulk Portfolio Name",
      field: "AOPortfolioID",
      type: 'leftColumn',
      width: 200,
      hide: false,
      pinned: true,
      suppressMovable: true,
      filter: "agTextColumnFilter",
      filterParams: { debounceMs: 2000, ...this.filterParams },
      valueGetter: params => params.data?.AOPortfolioID?.replace(/_/gi, ' '),
    },
  ];

  static columns: ColDef[] = [
    ...PropertyListingColumnDefinition.headerColumn,
    ...PropertyListingColumnDefinition.pinnedColumns,
    ...PropertyListingColumnDefinition.defaultColumns,
    ...PropertyListingColumnDefinition.uwDefaultColumns,
  ];

  static bulkColumns: ColDef[] = [
    ...PropertyListingColumnDefinition.pinnedColumns,
    ...PropertyListingColumnDefinition.bulkDefaultColumns,
    ...PropertyListingColumnDefinition.defaultColumns,
  ];
  static bulkColumnsSetting: ColDef[] = [
    ...PropertyListingColumnDefinition.pinnedColumns,
    ...PropertyListingColumnDefinition.defaultColumns,
  ];

  static reservedColumnsIdsDef = ['isHeaderRow','id','reasonFewComps', "missingDataTooltip", 'underwritingStatus'];

}
